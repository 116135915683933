import React, { useState, useRef, useEffect } from 'react';  
import { Helmet } from 'react-helmet'; // React Helmetをインポート  
import axios from 'axios';  
import './App.css'; // CSSファイルをインポート  
import yourImage from './girl.jpg'; // 画像をインポート  
  
function App() {  
  const [result, setResult] = useState(null);  
  const [imageSrc, setImageSrc] = useState(null);  
  const [error, setError] = useState(null); // エラーメッセージを保持するためのステート  
  const [loading, setLoading] = useState(false); // ローディング状態を保持するためのステート  
  const canvasRef = useRef(null);  
  const imageRef = useRef(new Image());  
  const dropZoneRef = useRef(null);  
  
  const handleFileChange = async (file) => {  
    setLoading(true); // ファイルアップロード前にローディングを開始  
    clearCanvas(); // 新しいファイルが選択されたらキャンバスをクリア  
    setResult(null); // 新しいファイルが選択されたら前の結果をクリア  
    setImageSrc(null); // 新しいファイルが選択されたら前の画像をクリア  
    setError(null); // 新しいファイルが選択されたらエラーをリセット  
  
    if (file) {  
      setImageSrc(URL.createObjectURL(file));  
  
      const formData = new FormData();  
      formData.append('file', file);  
  
      try {  
        const response = await axios.post('https://app-bisyo-bizyo-sensitive-judgment-backend-face-01.azurewebsites.net/analyze', formData, {  
          // const response = await axios.post('http://127.0.0.1:5000/analyze', formData, {  
          headers: {  
            'Content-Type': 'multipart/form-data',  
          },  
        });  
        setResult(response.data);  
      } catch (error) {  
        console.error(error);  
        if (error.response && error.response.data && error.response.data.error) {  
          setError('顔を検出できませんでした。サイズの大きい画像でお試しください');  
        } else {  
          setError('サーバーリクエストエラー');  
        }  
      } finally {  
        setLoading(false); // ファイルアップロードが完了したらローディングを終了  
      }  
    }  
  };  
  
  const handleInputChange = (e) => {  
    const file = e.target.files[0];  
    handleFileChange(file);  
  };  
  
  const handleDrop = (e) => {  
    e.preventDefault();  
    const file = e.dataTransfer.files[0];  
    handleFileChange(file);  
  };  
  
  const handleDragOver = (e) => {  
    e.preventDefault();  
  };  
  
  const clearCanvas = () => {  
    const canvas = canvasRef.current;  
    if (canvas) {  
      const ctx = canvas.getContext('2d');  
      ctx.clearRect(0, 0, canvas.width, canvas.height);  
    }  
  };  
  
  useEffect(() => {  
    if (result && imageSrc) {  
      const canvas = canvasRef.current;  
      const ctx = canvas.getContext('2d');  
      const image = imageRef.current;  
      image.src = imageSrc;  
  
      image.onload = () => {  
        canvas.width = image.width;  
        canvas.height = image.height;  
        ctx.drawImage(image, 0, 0);  
  
        result.forEach((res) => {  
          const { x, y, w, h } = res.region;  
          ctx.strokeStyle = 'red';  
          ctx.lineWidth = 6;  
          ctx.strokeRect(x, y, w, h);  
  
          const dominantEmotion = res.dominant_emotion;  
          const dominantGender = res.dominant_gender;  
          const dominantRace = res.dominant_race;  
  
          ctx.font = '40px Arial';  
          ctx.fillStyle = 'white';  
          ctx.fillText(`Age: ${res.age}`, x, y - 100);  
          ctx.fillText(`Gender: ${dominantGender}`, x, y - 65);  
          ctx.fillText(`Emotion: ${dominantEmotion}`, x, y - 33);  
          ctx.fillText(`Race: ${dominantRace}`, x, y);  
        });  
      };  
    }  
  }, [result, imageSrc]);  
  
  return (  
    <div className="App">  
      <Helmet>  
        <title>無料で年齢 性別 感情 人種 判定しちゃうぞ！びしょ美女.com</title>  
        <meta name="description" content="生成系AI美女のための精度が高い齢性別表情人種を作ってみました。無料で使えるのでぜひお試しください。" />  
        <meta name="keywords" content="年齢判定, 顔認識, 感情判定, 性別判定, 人種判定, びしょ美女, 生成系AI, AI美女, ビキニ, 水着, 無料" />  
        {/* Google Analytics Tag */}  
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-PL77Q7KHG9"></script>  
        <script>  
          {`  
            window.dataLayer = window.dataLayer || [];  
            function gtag(){dataLayer.push(arguments);}  
            gtag('js', new Date());  
            gtag('config', 'G-PL77Q7KHG9');  
          `}  
        </script>
      </Helmet>  
      <div className="left-area">  
        <h1>びしょ美女.com</h1>  
        <img src={yourImage} alt="Your description" style={{ width: '100%' }} />  
        <p><font size="6">おしらせ</font></p>  
        <p><font size="5">精度が高い年齢判定を作ってみました。</font></p>  
        <div className='text-msg'>  
          <a href="https://www.patreon.com/bibi_maiko">👉センシティブ判定</a>  
        </div>  
        <div className='text-msg'>  
          <a href="https://drip-girls.com/">👉びしょ美女.com</a>  
        </div>  
      </div>  
      <div className="right-area">  
        <div className="title">  
          <h2>💖年齢性別判定しちゃうぞ！感情も人種も判定しゃうぞ💖（プレビュー版）</h2>  
        </div>  
        <div  
          ref={dropZoneRef}  
          className="drop-zone"  
          onDrop={handleDrop}  
          onDragOver={handleDragOver}  
        >  
          <input type="file" onChange={handleInputChange} />  
          <p>👉画像のドラッグ＆ドロップでも大丈夫だよ👈処理に大変時間がかかることがあります！(; ･`д･´)</p>  
        </div>  
        {error && <div className="error">{error}</div>}  
        <div className="content">  
          {loading && <div className="spinner">Loading...</div>}  
          {!loading && (  
            <>  
              <div className="image-container">  
                {imageSrc && <canvas ref={canvasRef} />}  
              </div>  
              <div className="table-container">  
                {result && (  
                  <table border="1">  
                    <thead>  
                      <tr>  
                        <th>年齢</th>  
                        <th>感情</th>  
                        <th>性別</th>  
                        <th>人種</th>  
                      </tr>  
                    </thead>  
                    <tbody>  
                      {result.map((res, index) => (  
                        <tr key={index}>  
                          <td>{res.age}</td>  
                          <td>{res.dominant_emotion}</td>  
                          <td>{res.dominant_gender}</td>  
                          <td>{res.dominant_race}</td>  
                        </tr>  
                      ))}  
                    </tbody>  
                  </table>  
                )}  
                {result &&  
                  <div className='attention-message'>  
                    <p>センシティブ判定のサービス継続が難しくなってきました。</p>  
                    <p>サービス継続のための開発支援を何卒お願い致します！</p>  
                    <a href="https://www.patreon.com/bibi_maiko">👉Patreonで毎月$3から開発を支援する！</a>  
                    <p></p>
                    <a href="https://x.com/bisyo_bizyo">👉個別開発相談も承ります！気軽にDMください！</a> 
                    <p></p>
                  </div>  
                }  
              </div>  
            </>  
          )}  
        </div>  
      </div>  
    </div>  
  );  
}  
  
export default App;  
